import React, { Component, Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Spinner from "../app/shared/Spinner";

const AddActivity = lazy(() => import("./activities/AddActivity"));
 const EditActivity = lazy(() => import("./activities/EditActivity"));
const Activity = lazy(() => import("./activities/Activity"));
const AddProject = lazy(() => import("./projects/AddProject"));
 const EditProject = lazy(() => import("./projects/EditProject"));
const Project = lazy(() => import("./projects/Project"));
const SubProject = lazy(() => import("./projects/SubProjects"));
const AddSubProject = lazy(() => import("./projects/AddSubProjects"));
const AddClient = lazy(() => import("./Clients/AddClients"));
 const EditClient = lazy(() => import("./Clients/EditClients"));
const Client = lazy(() => import("./Clients/Clients"));
const AddHazard = lazy(() => import("./hazard/AddHazard"));
const Hazard = lazy(() => import("./hazard/Hazard"));
const UserManagement = lazy(() => import("./user/UserManagement"));
const AddUser = lazy(() => import("./user/AddUser"));
const EditUser = lazy(() => import("./user/EditUser"));
const EditHazard = lazy(() => import("./hazard/EditHazard"));
const Attendance = lazy(() => import("./attendance/Attendance"));
const Login = lazy(() => import('./user-pages/Login'));
const Account = lazy(() => import('./user-pages/Account'));
const PrivacyPolicy = lazy(() => import('./user-pages/PrivacyPolicy'));
const Assessment =lazy(() => import('./assessment/Assessment'));
const CheckList = lazy(() => import('./checklist/CheckLists'));
const AddCheckList = lazy(() => import('./checklist/AddCheckList'));
class AppRoutes extends Component {
  render() {
    return (
      <Suspense fallback={<Spinner />}>
        <Switch>
         {/* <Route  exact path="/user/user-management" component={UserManagement} /> */}
        
          {/* <Route exact path="/backoffice/dashboard" component={ Dashboard } /> */}
          <Route path="/hazard/hazard-management" component={Hazard} />
          <Route path="/hazard/hazard-management" component={Hazard} />
          <Route 
            path="/activity/activity-management"
            component={Activity}
          />
          <Route path="/activity/add-activity" component={AddActivity} />
          <Route path="/activity/edit-activity/:id" component={EditActivity} />
          <Route path="/hazard/add-hazard" component={AddHazard} />
          <Route path="/hazard/edit-hazard/:id" component={EditHazard} />
          <Route path="/project/edit-project/:id" component={EditProject} />
          <Route path="/project/add-project" component={AddProject} />
          <Route path="/project/project-management" component={Project} />
          <Route path="/project/add-subprojects" component={AddSubProject} />
          <Route path="/project/sub-projects" component={SubProject} />
          <Route path="/client/edit-client/:id" component={EditClient} />
          <Route path="/client/add-client" component={AddClient} />
          <Route path="/client/client-management" component={Client} />
          <Route path="/user/privacy-policy" component={PrivacyPolicy} />
          <Route path="/login" component={ Login } />
          <Route path="/user/add-user" component={AddUser} />
          <Route path="/assessment/assessment-management" component={Assessment} />
          <Route path="/checklist/checklist-management" component={CheckList} />
          <Route path="/checklist/add-checklist" component={AddCheckList} />
          <Route path="/user/edit-user/:id" component={EditUser} />
          <Route path="/attendance/attendance-management" component={Attendance} />
          <Route  exact  path="/account" component={Account} />
          {localStorage?.getItem("dusfantoken") ? (
          <Route
            exact
            path="/user/user-management"
            component={UserManagement}
          />
          ) : (
            <Redirect to="/login" />
          )}
          
          {localStorage?.getItem("dusfantoken") ? (
            <Redirect to="/user/user-management" />
          ) : (
            <Redirect to="/login" />
          )}
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;
